import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export type ValueValidationConfidence =
    'valid' |
    'invalid' |
    /**
     * Verification of automated check without confidence set
     */
    'verificationRequired' |
    'notApplicable'

export type ValueValidationType = 'py' | 'ic' | 'ma'

export const VAVALUE_VALIDATION_COLOR: Record<ValueValidationConfidence, string> = {
    valid: '#008000',
    invalid: '#FF0000',
    verificationRequired: '#FFA500',
    notApplicable: '#000000',
}

export const valueValidationTypes: ValueValidationType[] = ['py', 'ic', 'ma']

export type ValueValidationObj = Partial<Record<ValueValidationType, ValueValidationConfidence>>

export interface ValueValidation {
    companyId: string;
    reportId: string;
    entityId: string;

    /**
     * Related to "reportExtractedValues" collection
     */
    extractedValueId: string;

    manual?: ValueValidationObj
    auto?: ValueValidationObj
}

export const VALUE_VALIDATION_COLLECTION = 'valueValidation'

export const valueValidationRef = collection(db, VALUE_VALIDATION_COLLECTION)
