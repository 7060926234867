import { useEffect, useState } from 'react';

import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

export const useCurrentPage = () => {
    const { documentViewer } = useViewerDocument()
    
    const [page, setPage] = useState();

    useEffect(() => {
        if(!documentViewer) return

        documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
            setPage(pageNumber);
        });
    }, [documentViewer]);

    return page
}
