import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ReportExtractedValues {
    companyId: string;
    coords: [number, number, number, number];
    /**
     * Will be set by backend in case of manual extraction
     */
    normalizedValue?: number;
    originalValue: string;
    page: number;
    reportId: string;
    sourceFileName?: string;
    type: 'money' | 'manual'
}

export const REPORT_EXTRACTED_VALUES_COLLECTION = 'reportExtractedValues'

export const reportExtractedValuesRef = collection(db, REPORT_EXTRACTED_VALUES_COLLECTION)
