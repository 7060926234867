import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import 'pdfjs-dist/web/pdf_viewer.css';
import WebViewer from '@pdftron/pdfjs-express';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useVeltClient } from '@veltdev/react';
import { and, doc, query, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import { PdfContext, PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CONFIG } from '@/config.ts';
import { ReportExtractedValues, reportExtractedValuesRef } from '@/firestore/api/reportExtractedValues.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import {
    ValueValidation, ValueValidationConfidence,
    valueValidationRef, valueValidationTypes, VAVALUE_VALIDATION_COLOR,
} from '@/firestore/api/valueValidation.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { createRange } from '@/utils/createRange.ts';
import { ACTIVE_MONEY_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { useApplyAnnotations } from '@/widgets/PdfViewer2/PdfViewer2.hooks.ts';
import { AnnotationVariant, PdfViewer2Props } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { createAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useAnnotationsListener } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';
import { useToolsListener } from '@/widgets/PdfViewer2/useToolsListener.ts';

import { useAsyncEffect } from 'ahooks';

// Rename to annot type
export const enum CustomToolNames {
    TickMark = 'TickMark',
    SumSelect = 'SumSelect',
    CrossLink = 'CrossLink',
    ValueIdentify = 'ValueIdentify',
}

const VIEWER_LIB_STATIC_URL = `${window.location.protocol}//${window.location.host}/pdf-viewer/`;

/**
 * FIXME:
 * - Limit right click elements
 *
 * @todo: Wrap with 'Sentry.profiler' for performance monitoring
 *
 * LINKS:
 * 3 popup types: https://docs.apryse.com/documentation/web/guides/customizing-popup/
 */
export const PdfViewer2 = (props: PdfViewer2Props) => {
    const { fileUrl } = props;

    const { id: docId } = useParams()

    const { annotationManager, documentViewer } = useViewerDocument()

    const authData = useContext<AuthData>(AuthDataContext)

    const [activeMoneyValue] = useQueryParam(ACTIVE_MONEY_VALUE_QUERY_PARAM.name, ACTIVE_MONEY_VALUE_QUERY_PARAM.type)

    const [activePage, setActivePage] = useQueryParam('page', NumberParam)

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        // where('companyId', '==', authData.company.id),
        where('reportId', '==', docId),
    )))

    const [valueValidationSnap, valueValidationLoading] = useCollection<ValueValidation>(valueValidationRef, and(
        where('reportId', '==', docId),
    ))

    const page = useCurrentPage()

    useEffect(() => {
        if (page) {
            console.log('loading page', page)
            setActivePage(page)
        }
    }, [page])

    const [reportExtractedValuesSnap, reportExtractedValuesLoading] = useCollection<ReportExtractedValues>(query(reportExtractedValuesRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['money', 'manual']),
    )))

    const [reportSnapshot] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const reportData = reportSnapshot?.data()

    const instansInited = useRef(false);
    const viewer = useRef(null);
    const { pdfInstance, setPdfInstance } = useContext<PdfContext>(PdfViewerContext2)

    const { client: veltClient } = useVeltClient()

    useToolsListener()
    useAnnotationsListener({
        reportSnapshot,
    })

    const [annotationsReady, setAnnotationsReady] = useState(false)

    useAsyncEffect(async () => {
        if (!documentViewer) return

        // Supposedly it's the beset time when we can start draw annotatations
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            setAnnotationsReady(true)
        }).catch((e) => {
            console.error('Error loading annotations', e)
        })
    }, [documentViewer])

    const addCustomTools = useCallback((instance: WebViewerInstance, docViewer: Core.DocumentViewer) => {

    }, [])

    useApplyAnnotations({})

    const annotationContextOn = authData?.company?.data()?.features?.includes('annotationContext') ?? false

    /**
     * Apply links ecdf-notes
     */
    useAsyncEffect(async () => {
        if (!reivewLinkObjectsSnap || !annotationManager || revewLinkObjectsLoading || !pdfInstance || !annotationsReady || reportExtractedValuesLoading) return
        if (reportData?.reviewStatus !== 'inProgress') return

        if (annotationContextOn) {
            return
        }

        const filtered = reivewLinkObjectsSnap.docs
        // TEmprorary disabled and we don't care about saving an info info database
        //     .filter((linkObject) => {
        //     return !linkObject.data().linksCreated
        // })

        const annotationsList = annotationManager.getAnnotationsList()

        for (const linkObject of filtered) {
            const linkData = linkObject.data()

            const groupUniqueKey = linkObject.id

            for (let i = 0; i < linkData.linksGroup.length; i++) {
                const linkItem = linkData.linksGroup[i]

                const { coords, page, content, label } = linkItem

                if (!coords) {
                    console.error('No coords for link', linkItem)
                    continue
                }

                const normalizedValue =
                    ( linkData.type === 'money')
                        ? reportExtractedValuesSnap?.docs.find((doc) => doc.ref.id === linkItem.extractedValueId)?.data().normalizedValue ?? null
                        : null

                const annotation = await createAnnotation({
                    annotationsList,
                    padding: 1,
                    annotationManager,
                    pdfInstance: pdfInstance,
                    type: 'link',
                    pageIndex: page,
                    coordinates: coords,
                    showInNotesPanel: false,
                    toolName: CustomToolNames.CrossLink,
                    relatedSnapshotId: linkObject.id + '_' + i,
                    annotationVariant: normalizedValue?.toString() === activeMoneyValue?.toString() ? AnnotationVariant.moneyValueActive : undefined,
                    reply: linkData.type === 'money' ? 'Same values found' : 'eCDF - Note',
                    excludeFromSummary: true,
                    customData: {
                        linkGroup: groupUniqueKey,
                        tickConfidence: 'link', // FIXME: Rename later to style/type. Related to Identified blocks type.
                        linkLabel: label,
                        ignoreStepKey: 'true',
                        toolName: CustomToolNames.CrossLink,
                        relatedLinkSnapshotId: linkObject.id,
                        linkIndex: linkData.linksGroup.indexOf(linkItem).toString(),
                        crossLInkType: linkData.type,
                        normalizedValue: normalizedValue,
                    },
                })

                console.log('LINK: created', annotation)
            }
        }
    }, [annotationManager, revewLinkObjectsLoading, pdfInstance, reportData?.currentStep, annotationsReady, reportExtractedValuesLoading, activeMoneyValue]);

    /**
     * Lazy apply of extracted 'Money' annotations
     */
    useEffect(() => {
        if (
            !reportExtractedValuesSnap ||
            !annotationManager ||
            reportExtractedValuesLoading ||
            !annotationsReady ||
            !pdfInstance ||
            !documentViewer ||
            valueValidationLoading
        ) return

        if (!annotationContextOn) {
            return;
        }

        const notInProgress = reportData?.reviewStatus !== 'inProgress'

        const applyForPages = createRange(page, CONFIG.LAZY_ANNOTATIONS_PAGE_DISTANCE, 1, documentViewer.getPageCount())

        const moneyValues = reportExtractedValuesSnap.docs
            // Only pages in nearest range
            .filter((el) => {
                return applyForPages.includes(el.data().page)
            })

        console.log('apply values', moneyValues)

        const annotationsList = annotationManager.getAnnotationsList()

        for (const moneyValue of moneyValues) {
            const { coords, page: annotPage, originalValue, normalizedValue } = moneyValue.data()

            if (!coords) {
                console.error('No coords for moneyValue', moneyValue)
                continue
            }

            if(!notInProgress) {
                createAnnotation({
                    annotationsList,
                    annotationManager,
                    pdfInstance: pdfInstance,
                    pageIndex: annotPage,
                    padding: 1,
                    commentTitle: 'Extracted value: ' + originalValue,
                    coordinates: coords,
                    showInNotesPanel: true,
                    excludeFromSummary: true,
                    annotationVariant: AnnotationVariant.moneyValue,
                    relatedSnapshotId: moneyValue.id,
                    readOnly: true,
                    customData: {
                        relatedSnapshotId: moneyValue.id,
                        skipEventHandlers: 'true',
                        normalizedValue: normalizedValue,
                        originalValue: originalValue,
                        annotationVariant: AnnotationVariant.moneyValue,
                    },
                })
            }

            const relatedValueValidation = valueValidationSnap?.docs.find((doc) => {
                return doc.data().extractedValueId === moneyValue.id
            })

            if (relatedValueValidation) {
                const updateAnnotationTicks = (valueValidationS) => {
                    const valueValidation = valueValidationS.data()

                    let indexStart = 0
                    const updateAnnotationTicks: ({
                        text: string,
                        color: string,
                        indexStart
                    } | null)[] = valueValidationTypes.map((type, index) => {
                        const conf = valueValidation['manual']?.[type] || valueValidation['auto']?.[type]

                        const displayConfList: ValueValidationConfidence[] = ['valid', 'invalid']

                        const displayConf = displayConfList.includes(conf)

                        if (!conf || !displayConf) return null

                        const color = VAVALUE_VALIDATION_COLOR[conf]

                        const text = type.toUpperCase()

                        const res = {
                            indexStart,
                            color,
                            text,
                        }

                        // 1 - space
                        indexStart += text.length + 1

                        return res
                    }).filter(Boolean)

                    const textContent = updateAnnotationTicks.map(el => el.text).join(' ')

                    const uniqueId = moneyValue.id + '_' + textContent + updateAnnotationTicks.map(el => el?.color).join(' ')

                    createAnnotation({
                        annotationsList,
                        annotationManager,
                        pdfInstance: pdfInstance,
                        annotationType: 'FreeText',
                        pageIndex: annotPage,
                        // Need only two because of autosize
                        // 24, 4 - width/height of the annot
                        coordinates: [coords[0], coords[1] + coords[3] + 1, 24, 4],
                        showInNotesPanel: false,
                        annotationVariant: AnnotationVariant.valueConfidence,
                        fondSize: '5pt',
                        bold: true,
                        relatedSnapshotId: valueValidationS.id,
                        snapshotRerenderKey: uniqueId,
                        textContent: textContent,
                        readOnly: true,
                        richTextStyle: updateAnnotationTicks.reduce((acc, el) => {
                            acc[el.indexStart] = {
                                'color': el.color,
                                'font-weight': 'bold',
                            }
                            return acc
                        }, {}),
                        customData: {
                            skipEventHandlers: 'true',
                            annotationVariant: AnnotationVariant.valueConfidence,
                        },
                    })
                }

                updateAnnotationTicks(relatedValueValidation)
            }
        }
    }, [
        annotationsReady,
        reportExtractedValuesLoading,
        reportExtractedValuesSnap,
        annotationManager,
        pdfInstance,
        page,
        documentViewer,
        valueValidationLoading,
        valueValidationLoading,
        valueValidationSnap,
        reportData?.reviewStatus,
    ]);

    // Annot hover cursor change
    // useEffect(() => {
    //     if(!documentViewer || !pdfInstance) return
    //     documentViewer.addEventListener('toolModeUpdated', (tool) => {
    //         // debugger
    //         if (tool instanceof pdfInstance.Core.Tools.PolygonCreateTool) {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = false;
    //         } else {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = true;
    //         }
    //     });
    // }, [documentViewer]);

    // FIt to width for small screen
    useEffect(() => {
        if (!pdfInstance) return

        // 15 inch Mac screen: 1512
        if (window.innerWidth < 1600) {
            pdfInstance.UI.setFitMode(pdfInstance.UI.FitMode.FitWidth)
        }
    }, [pdfInstance]);

    const initVeltComments = useCallback((instance: WebViewerInstance, annotManager: Core.AnnotationManager) => {
        instance.UI.annotationPopup.add(
            {
                type: 'actionButton',
                title: 'Comment',
                img: 'icon-tool-comment-line',
                onClick: () => {
                    const annots = annotManager.getSelectedAnnotations();
                    if (!annots || !annots[0]) {
                        return;
                    }
                    const [annot] = annots;
                    const id = annot.Id

                    annotManager.redrawAnnotation(annot);

                    const locationName = id
                    const locationId = id

                    const locationConfig = {
                        id: locationId,
                        locationName,
                    }

                    veltClient.setLocation(locationConfig)
                    veltClient.setLocation({
                        id: 'step-1',
                        locationName: 'Step 1',
                    }, true)

                    // Activate filter in Sidebar by account
                    const filters = {
                        location: [
                            { id: locationId },
                        ],
                    }
                    const commentElement = veltClient.getCommentElement()
                    commentElement.setCommentSidebarFilters(filters)
                },
            },
        )
    }, [veltClient])

    useEffect(() => {
        if (!documentViewer) return

        if (activePage) {
            documentViewer?.setCurrentPage(activePage)
        }
    }, [documentViewer]);

    // Reset Velt filter on pageLoading
    useEffect(() => {
        if (!veltClient || !pdfInstance || !annotationManager) return

        const filters = {
            location: [],
        }
        const commentElement = veltClient.getCommentElement()
        commentElement.setCommentSidebarFilters(filters)

        // FIXME: Return later
        // initVeltComments(pdfInstance, annotationManager)
    }, [veltClient, pdfInstance, annotationManager]);

    useEffect(() => {
        if (instansInited.current || !fileUrl) {
            return;
        }
        instansInited.current = true

        WebViewer(
            {
                path: VIEWER_LIB_STATIC_URL,
                initialDoc: fileUrl,
                licenseKey: 'qu1Vr7UA7e8XcicdCcZ8',
                // Allows to modify annotations from other users
                isAdminUser: true,
            },
            viewer.current,
        ).then((instance: WebViewerInstance) => {
            const docViewer = instance.docViewer as Core.DocumentViewer;

            // Keeps only main tools active on the panel
            instance.UI.disableElements([
                'toolbarGroup-Annotate',
                'toolbarGroup-Insert',
                'toolbarGroup-Shapes',
                'toolbarGroup-Measure',
                'toolbarGroup-Forms',
                'toolbarGroup-Edit',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-View',
                'toolsHeader',
            ]);

            // Turn off comments
            instance.UI.disableElements([
                // FIXME: Uncomment and switch to Velt comments
                // 'annotationCommentButton',
                // 'notesPanelButton',
                // 'notesPanel',
                // 'toggleNotesButton',
            ]);

            // Note tooltip
            instance.UI.disableElements([
                'linkButton',
                'annotationStyleEditButton',
                'annotationGroupBu-tton',
            ]);

            addCustomTools(instance, docViewer)

            setPdfInstance(instance)
        })
            .catch((error) => {
                console.error('Error loading WebViewer:', error);

            })
    }, [fileUrl]);

    useEffect(() => {
        if (!annotationManager) return

        // Identify user
        annotationManager.setCurrentUser(authData.user.displayName)

        /**
         * Disabled multiple annotations selection
         * Annotations listener properly handling now only one annotation
         */
        annotationManager.addEventListener('annotationSelected', (annotations) => {
            if (annotations.length > 1) {
                annotationManager.deselectAnnotations(annotations.splice(0))
            }

            // TODO: Later name it work only for customTools
            annotations?.forEach((annotation) => {
                annotation.setRotationControlEnabled(false);
            })
        })
    }, [annotationManager]);

    return (
        <div
            id='velt-pdf-viewer'
            data-velt-pdf-viewer='true'
            data-velt-iframe-container='true'
            style={{
                'flex': 1,
                'height': '100%',
            }}
            ref={viewer}
        />
    );
}
