import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react'

import { GatewayOutlined } from '@ant-design/icons';
import { Button, CollapseProps, Flex, Result, Typography, theme } from 'antd';
import { Collapse } from 'antd/lib';
import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import { ReportExtractedValues, reportExtractedValuesRef } from '@/firestore/api/reportExtractedValues.ts';
import { valueValidationTypes,
} from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { ConfidencePicker, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM } from '@/pages/ReviewPage';
import { IDENTIFY_VALUE_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';

import { AnnotationContextProps } from './AnnotationContext.types'

export const AnnotationContext = (props: AnnotationContextProps) => {
    const { annotationManager } = useViewerDocument()

    const [focusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)
    const [identifyValOpen, setIdentifyValOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)
    
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const lastSelectedAnnotation = useRef(focusedSnapIdParam)

    const [value, setValue] = useState()
    
    const annotationId = focusedSnapIdParam ?? lastSelectedAnnotation.current

    const [extractedValuesSnap, extractedValueLoading] = useDocument<ReportExtractedValues>(annotationId ? doc(reportExtractedValuesRef, annotationId) : undefined)

    useEffect(() => {
        if(!focusedSnapIdParam) return
        lastSelectedAnnotation.current = focusedSnapIdParam
    }, [focusedSnapIdParam]);
    
    useEffect(() => {
        if (!annotationManager || !annotationId || extractedValueLoading) return

        const dataStap = extractedValuesSnap
        const data = dataStap?.data()
        
        if (!data) return;

        const normValue = data.originalValue

        setValue(normValue)
    }, [annotationManager, annotationId, extractedValuesSnap, extractedValueLoading]);

    const text = ''

    const { token } = theme.useToken();

    const itemStyle : CSSProperties = {
        borderRadius: 0,
        cursor: 'default',
    }

    const items: CollapseProps['items'] = valueValidationTypes.map(type => ({
        key: type,
        style: itemStyle,
        destroyInactivePanel: true,
        collapsible: 'disabled',
        label: (
            <ConfidencePicker
                type={type}
                selectedAnnotationId={annotationId}
                focusedValueSnap={extractedValuesSnap}
            />
        ),
        children: <p>{text}</p>,
    }))

    return (
        <Flex
            style={{
                flexGrow: 1,
                alignItems: !extractedValuesSnap?.exists() ? 'center' : 'flex-start',
                justifyContent: !extractedValuesSnap?.exists() ? 'center' : 'flex-start',
                padding: '16px 0',
            }}
        >
            {extractedValuesSnap?.exists() ? (
                <Flex vertical style={{ width: '100%' }}>
                    <span style={{ textAlign: 'center' , padding: '0 16px' }}>
                        <Typography.Title
                            style={{
                                display: 'inline',
                                borderBottom: `solid 3px ${COLORS.main}`,
                                height: 'fit-content',
                            }}
                        >
                            {value ? value : '—'}
                        </Typography.Title>
                    </span>
                    <Flex vertical style={{ width: '100%', marginTop: 24 }}>
                        <Collapse
                            accordion items={items} style={{
                                borderRadius: 0,
                                borderLeft: 'none',
                                borderRight: 'none',
                            }}
                        />
                    </Flex>
                </Flex>
            ) : (
                <Result
                    icon={<GatewayOutlined/>}
                    title='Please select a value'
                    extra={(
                        <Button
                            onClick={() => {
                                setIdentifyValOpen(true)
                            }}
                        >Create a New</Button>
                    )}
                />
            )}
        </Flex>
    )
}
