import { WebViewerInstance } from '@pdftron/webviewer';

export interface PdfViewer2Props {
    fileUrl: string
}

export enum AnnotationVariant {
    moneyValue = 'moneyValue',
    moneyValueActive = 'moneyValueActive',
    valueConfidence = 'valueConfidence',
}

export interface AnnotationConfig {
    StrokeColor?: unknown
    FillColor?: unknown
    Opacity?: number
    StrokeThickness?: number
    BorderStyle?: 'dash' | 'solid'
}

export const getAnnotationConfigByVariant = (pdfInstance: WebViewerInstance): Record<AnnotationVariant, AnnotationConfig> => ({
    [AnnotationVariant.moneyValue]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(22, 117, 147),
        Opacity: 0.4,
        StrokeThickness: 0.8,
        BorderStyle: 'dash',
        // Just to keep it clickable
        FillColor: new pdfInstance.Core.Annotations.Color(22, 117, 147, 0.05),
    },
    [AnnotationVariant.moneyValueActive]: {
        StrokeColor: new pdfInstance.Core.Annotations.Color(22, 117, 147),
        Opacity: 0.4,
        StrokeThickness: 1,
        BorderStyle: 'solid',
    },
    [AnnotationVariant.valueConfidence]: {
        Opacity: 0.7,
        StrokeThickness: 0,
    },
})
