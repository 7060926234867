import { FormItemProps } from 'antd';
import { collection } from 'firebase/firestore';

import { S213Format } from '@/pages/RegRepPage';

import { db } from '../config.ts';

/**
 * Use it for form/table etc
 */
export const REG_REP_FORM_ITEMS: Record<Partial<keyof RegRepProcess>, FormItemProps> = {
    status: {
        label: 'Status',
        name: 'status',
        required: true,
    },
    s213format: {
        label: 'Choose S213 file format',
        name: 'fileS213format',
        required: true,
        rules: [{
            message: 'The format specifies the list of files to be uploaded',
        }],
    },
    fileS213: {
        label: 'File S2.13 (Generic)',
        name: 'fileS213',
        rules: [{
            message: 'Please upload S213 file in generic format',
            required: true,
        }],
    },
    fileS213bob50: {
        label: 'Trial balance (BOB50)',
        name: 'fileS213bob50',
        rules: [{
            message: 'Please upload S213 in BOB50 format',
            required: true,
        }],
    },
    fileS213Bob50Mapping: {
        label: 'Mapping file',
        name: 'fileS213Bob50Mapping',
        rules: [{
            message: 'Please upload S213 mapping for BOB50 format',
            required: true,
        }],
    },
    clientCompanyId: {
        name: 'clientCompanyId',
        label: 'Client',
        rules: [{
            message: 'Please  select the company associated with this report',
            required: true,
        }],
    },
    entityId: {
        name: 'entityId',
        label: 'Entity',
        rules: [{
            required: true,
            message: 'Please select the entity name associated with this report',
        }],
    },
    fileTPTOBS: {
        label: 'File TPTOBS',
        name: 'fileTPTOBS',
        required: true,
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
    fileU11: {
        label: 'File U1.1',
        name: 'fileU11',
        required: true,
        // rules: [{
        //     message: 'Please upload TPTOBS file',
        // }],
    },
}

export type RegRepProcessStatus =
    'preparingS213' |
    /**
     * Initial status
     */
    'processing' |
    /**
     * Processing is failed
     */
    'error' |
    /**
         * Processing is successfully finished
         */
    'done'

export type RegRepProcessValidationStatus =
    'processing' | 'warning' | 'failed' | 'noErrors'

export interface RegRepProcessFile {
    name: string,
    file: string
}

export enum Period {
    quarterly = 'quarterly',
    monthly = 'monthly',
}

export enum UploadType {
    final = 'final',
    provisional = 'provisional',
}

export interface RegRepProcess {
    companyId: string
    userId: string
    clientCompanyId: string
    entityId: string
    uploadType: UploadType
    period: Period

    s213format: S213Format

    status: RegRepProcessStatus

    validationStatus: RegRepProcessValidationStatus

    /**
     * Input files s213 pipeline
     */
    fileS213?: string
    fileS213bob50?: string
    fileS213Bob50Mapping?: string
    fileTPTOBS: string
    fileU11: string

    /**
     * Output files s213 pipeline
     */
    fileS213Errors: RegRepProcessFile,
    fileS0213Converted: RegRepProcessFile,
    fileU11Errors: RegRepProcessFile,
    fileU11Converted: RegRepProcessFile,
    fileTPTErrors: RegRepProcessFile,
    fileTPTConverted: RegRepProcessFile,
    fileS213TPTOBSErrors: RegRepProcessFile,
    fileU11TPTOBSErrors: RegRepProcessFile

    uploadedAt: Date
}

type OutputFileKeys = Extract<keyof RegRepProcess,
'fileS213Errors' |
'fileS0213Converted' |
'fileU11Errors' |
'fileU11Converted' |
'fileTPTErrors' |
'fileTPTConverted' |
'fileS213TPTOBSErrors' |
'fileU11TPTOBSErrors'>;

export const regRepResultFilesKeys : OutputFileKeys[] = [
    'fileS213Errors',
    'fileS0213Converted',
    'fileU11Errors',
    'fileU11Converted',
    'fileTPTErrors',
    'fileTPTConverted',
    'fileS213TPTOBSErrors',
    'fileU11TPTOBSErrors',
];

export const REG_REP_PROCESS_COLLECTION = 'regRepProcess'

export const regRepProcessRef = collection(db, 'regRepProcess')
