import React, { useContext, useState } from 'react'

import { CloseOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { useAsyncEffect } from 'ahooks';
import { Button, Flex, MenuProps, Table, Tag, Typography, Dropdown, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { message } from 'antd/lib';
import { format } from 'date-fns/format';
import { where, query, orderBy, getDoc, doc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Outlet, useNavigate } from 'react-router-dom';

import { firebaseDownloadUrl } from '@/api/utils/firebase/firebaseDownloadUrl.ts';
import { DATE_FORMAT_MINUTE } from '@/constants/dates.ts';
import { clientCompanyRef } from '@/firestore/api/clientCompany.ts';
import { companyEntityRef } from '@/firestore/api/companyEntity.ts';
import { User } from '@/firestore/api/user.ts';
import { downloadFile } from '@/firestore/utils/downloadFile.ts';
import { ReportReviewTableItem } from '@/pages/FilesPage';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';

import { AuthData, AuthDataContext } from '../../components/containers/AuthContext';
import { ROUTES_CONFIG_REG_REP } from '../../constants/routes.ts';
import {
    RegRepProcess,
    regRepProcessRef,
    regRepResultFilesKeys,
    UploadType,
} from '../../firestore/api/regRepProcess.ts';

const statusColorByName: Record<RegRepProcess['status'], string> = {
    preparingS213: 'processing',
    processing: 'processing',
    error: 'error',
    done: 'success',
}

const statusTitleByName: Record<RegRepProcess['status'], string> = {
    preparingS213: 'Extracting mappings',
    processing: 'Processing',
    error: 'Extraction Failed',
    done: 'Success',
}

const validationStatusColorByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'processing',
    warning: 'warning',
    failed: 'error',
    noErrors: 'success',
}

const validationStatusTitleByName: Record<RegRepProcess['validationStatus'], string> = {
    processing: 'Validating',
    warning: 'Errors Found',
    noErrors: 'No Errors Found',
    failed: 'Failed',
}

const tableColumns = (username): ColumnsType<RegRepProcess> => ([
    {
        title: 'Uploaded At',
        dataIndex: 'uploadedAt',
        key: 'uploadedAt',
        width: 170,
        render: (value) => (
            format(value.toDate(), DATE_FORMAT_MINUTE)
        ),
    },
    {
        title: 'Client',
        width: 150,
        dataIndex: 'company',
        key: 'company',
        render: (value) => {
            return value.name
        },
    },
    {
        title: 'Entity',
        width: 150,
        dataIndex: 'entity',
        key: 'entity',
        render: (value) => {
            return value.name
        },
    },
    // {
    //     title: 'Reporting Period',
    //     width: 100,
    //     dataIndex: 'period',
    //     key: 'period',
    //     render: (value) => {
    //         return firstLetterToUpperCase(value)
    //     },
    // },
    {
        title: 'Type',
        width: 70,
        dataIndex: 'uploadType',
        key: 'uploadType',
        render: (value) => {
            return firstLetterToUpperCase(value)
        },
    },
    {
        width: 150,
        title: 'Uploaded by',
        dataIndex: 'user',
        key: 'user',
        render: (value: User) => value?.displayName ?? '—',
    },
    {
        width: 150,
        title: 'Files Uploaded',
        render: (value, item: RegRepProcess) => {
            const uploadedFiles: string[] = []
                
            if(item.s213format === 'bob50') {
                if(item.fileS213bob50) {
                    uploadedFiles.push('Trial balance (BOB50)')
                }
                if(item.fileS213Bob50Mapping) {
                    uploadedFiles.push('Mapping file')
                }
            } else if(item.s213format === 'generic') {
                if(item.fileS213) {
                    uploadedFiles.push('S213')
                }
            }

            if (item.fileTPTOBS) {
                uploadedFiles.push('TPTOBS')
            }
            if (item.fileU11) {
                uploadedFiles.push('U11')
            }

            return (
                <Flex gap={4} wrap='wrap'>
                    {uploadedFiles.map((file) => (
                        <Tag key={file} style={{ margin: 0 }}>{file}</Tag>
                    ))}
                </Flex>
            )
        },
    },
    // {
    //     width: 120,
    //     title: 'Processing Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (value, item) => (
    //         <Tag color={statusColorByName[item.status]}>
    //             {statusTitleByName[item.status]}
    //         </Tag>
    //     ),
    // },
    {
        width: 150,
        title: 'Validation Status',
        dataIndex: 'validationStatus',
        key: 'validationStatus',
        render: (value, item) => {
            // First - show processing progress
            if(item.status === 'processing' || item.status === 'error') {
                return (
                    <Tag color={statusColorByName[item.status]}>
                        {statusTitleByName[item.status]}
                    </Tag>
                )
            }

            return (
                <Tag color={validationStatusColorByName[item.validationStatus]}>
                    {validationStatusTitleByName[item.validationStatus]}
                </Tag>
            )
        },
    },
    {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 230,
        render: (_, item) => {
            const items: MenuProps['items'] =
            regRepResultFilesKeys
                .filter((key) => item[key])
                .map((key) => {
                    return (
                        {
                            key: key,
                            label: (
                                <Typography.Link
                                    onClick={() => {
                                        firebaseDownloadUrl(item[key].file).then((url) =>{
                                            downloadFile(url, item[key].name)
                                        }).catch(() => {
                                            message.error('Can\'t download the file')
                                        })
                                    }}
                                >
                                    {item[key].name}
                                </Typography.Link>
                            ),
                        }
                    )
                });

            return (
                <Dropdown.Button
                    disabled={item.status === 'processing' || item.status === 'error'}
                    menu={{ items }}
                    icon={<DownloadOutlined/>}
                    // onClick={() => {
                    //     if(!item.fileS0213Converted) {
                    //         message.error('No results to download')
                    //         return
                    //     }
                    //
                    //     firebaseDownloadUrl(item.fileS0213Converted.file).then((url) =>{
                    //         downloadFile(url, item.fileS0213Converted.name)
                    //     }).catch(() => {
                    //         message.error('Can\'t download the file')
                    //     })
                    // }}
                    type='primary'
                >
                    Download Results
                </Dropdown.Button>
            )
        },
    },
])

export const RegRepPage = Sentry.withProfiler(() => {
    const authData = useContext<AuthData>(AuthDataContext)
    const navigate = useNavigate()
    const userName = authData.user.displayName

    const [clientCompanySnap] = useCollection(query(clientCompanyRef, where('companyId', '==',authData.company.id)) )
    const [entitySnap] = useCollection(query(companyEntityRef, where('companyId', '==',authData.company.id)) )

    const [clientFilter, setClientFilter] = useState<string | null>(null)
    const [entityFilter, setEntityFilter] = useState<string | null>(null)
    const [periodFilter, setPeriodFilter] = useState<string | null>(null)
    const [typeFilter, setTypeFilter] = useState<string | null>(null)
    const [uploadedByFilter, setUploadedByFilter] = useState<string | null>(null)

    const [regRepProcessSnapshot, regRepProcessLoading] = useCollection<RegRepProcess>(
        // @ts-expect-error
        query( regRepProcessRef, orderBy('uploadedAt', 'desc')),
        where('companyId', '==', authData.company.id),
    )

    const [tableData, setTableData] = useState<RegRepProcess[]>([])

    if(!authData.company.data()?.features?.includes('showRegRep')) {
        return (
            <Flex vertical gap={20} style={{ padding: 16 }}>
                <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                <Typography.Text>
                    You don't have access to this feature
                </Typography.Text>
            </Flex>
        )
    }

    // Ingect data into
    useAsyncEffect( async () => {
        if(!regRepProcessSnapshot?.size) return;

        const modifiedItems: ReportReviewTableItem[] = regRepProcessSnapshot.docs
            .map(el => ({ ...el.data(), docRef: el.ref }))

        try {
            await Promise.all(
                modifiedItems.map(async (report) => {
                    const [
                        entity,
                        company,
                    ] = await Promise.all([getDoc(doc(companyEntityRef, report.entityId)), getDoc(doc(clientCompanyRef, report.clientCompanyId))])

                    report.entity = entity.data()
                    report.company = company.data()
                    report.user = authData.usersInCompany.find(user => user.uid === report.userId)
                    report.navigate = navigate
                }),
            )
        } catch (e) {
            console.error('Error while fetching data for reg rep table', e)
        }

        // TODO: Move this logic to firebase level and filter archieved items out by default
        // const filteredReports = modifiedItems.filter((item) => !item.archived)

        const res = modifiedItems.filter((item: ReportReviewTableItem) => {
            if(clientFilter && item.clientCompanyId !== clientFilter) return false
            if(periodFilter && item.period !== periodFilter) return false
            if(typeFilter && item.uploadType !== typeFilter) return false
            if(uploadedByFilter && item.userId !== uploadedByFilter) return false
            if(entityFilter && item.entityId !== entityFilter) return false
            return true
        })

        setTableData(res)
    }, [regRepProcessSnapshot, clientFilter, periodFilter, typeFilter, uploadedByFilter, entityFilter])

    return (
        <>
            <Flex gap={20} style={{ margin: '30px 16px 0 16px' }}>
                <Select
                    value={clientFilter}
                    allowClear={true}
                    onClear={() => {
                        setClientFilter(null)
                    }}
                    style={{
                        width: 200,
                    }}
                    placeholder='Client'
                    options={clientCompanySnap?.docs?.map((doc) => ({
                        label: doc.data().name,
                        value: doc.id,
                    }))}
                    onSelect={(value) => {
                        setClientFilter(value)
                        setEntityFilter(null)
                    }}
                />
                <Select
                    value={entityFilter}
                    allowClear={true}
                    onClear={() => {
                        setEntityFilter(null)
                    }}
                    style={{
                        width: 200,
                    }}
                    placeholder='Entity'
                    // Filter by company if selected
                    options={entitySnap?.docs?.filter(el => clientFilter ? (el.data().clientCompanyId === clientFilter) : true).map((doc) => ({
                        label: doc.data().name,
                        value: doc.id,
                    }))}
                    onSelect={(value) => {
                        setEntityFilter(value)
                    }}
                />
                {/*<Select*/}
                {/*    value={periodFilter}*/}
                {/*    allowClear={true}*/}
                {/*    options={Object.values(Period).map((period) => ({*/}
                {/*        label: firstLetterToUpperCase(period),*/}
                {/*        value: period,*/}
                {/*    }))}*/}
                {/*    placeholder='Reporting Period'*/}
                {/*    style={{*/}
                {/*        width: 200,*/}
                {/*    }}*/}
                {/*    onSelect={(value) => {*/}
                {/*        setPeriodFilter(value)*/}
                {/*    }}*/}
                {/*    onClear={() => {*/}
                {/*        setPeriodFilter(null)*/}
                {/*    }}*/}
                {/*/>*/}
                <Select
                    value={typeFilter}
                    allowClear={true}
                    placeholder='Type'
                    style={{
                        width: 200,
                    }}
                    onSelect={(value) => {
                        setTypeFilter(value)
                    }}
                    onClear={() => {
                        setTypeFilter(null)
                    }}
                    options={Object.keys(UploadType).map((type) => ({
                        label: firstLetterToUpperCase(type),
                        value: type,
                    }))}
                />
                <Select
                    value={uploadedByFilter}
                    placeholder='Uploaded By'
                    style={{
                        width: 200,
                    }}
                    onSelect={(value) => {
                        setUploadedByFilter(value)
                    }}
                    allowClear={true}
                    onClear={() => {
                        setUploadedByFilter(null)
                    }}
                    options={authData.usersInCompany.map((user) => ({
                        label: user.displayName,
                        value: user.uid,
                    }))}
                />
                <Button
                    icon={<CloseOutlined/>}
                    onClick={() => {
                        setClientFilter(null)
                        setPeriodFilter(null)
                        setTypeFilter(null)
                        setUploadedByFilter(null)
                    }}
                >Reset</Button>
            </Flex>
            <Flex vertical gap={20} style={{ padding: 16, paddingTop: 0 }}>
                <Flex
                    align='baseline' justify='space-between' gap={16}
                    flex={1}
                >
                    <Typography.Title level={4}>Regulatory Reporting</Typography.Title>
                    <Button
                        icon={<PlusOutlined/>}
                        type='primary'
                        onClick={() => {
                            navigate(ROUTES_CONFIG_REG_REP.REG_REP_NEW.path)
                        }}
                        style={{
                            width: 'unset',
                        }}
                    >
                        {ROUTES_CONFIG_REG_REP.REG_REP_NEW.title}
                    </Button>
                </Flex>
                <Table
                    loading={regRepProcessLoading}
                    columns={tableColumns(userName)}
                    dataSource={tableData}
                    bordered
                    scroll={{ scrollToFirstRowOnChange: true, y: 500 }}
                />
            </Flex>
            <Outlet/>
        </>
    )
})
