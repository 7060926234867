export function createRange(
    center: number,
    distance: number,
    minLimit?: number,
    maxLimit?: number,
): number[] {
    const start = minLimit !== undefined ? Math.max(center - distance, minLimit) : center - distance;
    const end = maxLimit !== undefined ? Math.min(center + distance, maxLimit) : center + distance;
  
    const range: number[] = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }
    return range;
}
