import React from 'react'

import { BlockOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { useQueryParam } from 'use-query-params';

import { TICK_MARK_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';

/**
 * TickMark component
 * Annotations handled in useAnnotationsListener hook
 */
export const TickMark = (props: {onClick: () => void}) => {
    const { onClick } = props
    
    const [isOpen, setIsOpen] = useQueryParam(TICK_MARK_QUERY_CONFIG.name, TICK_MARK_QUERY_CONFIG.type)

    return (
        <FloatButton
            type={isOpen ? 'primary' : 'default'}
            onClick={() => {
                setIsOpen(!isOpen)
                onClick()
            }}
            shape='square'
            icon={<BlockOutlined/>}
        />
    )
}
