import React, { useContext, useEffect, useState } from 'react'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import { Button, Flex, Tooltip, Typography } from 'antd';
import { getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import {
    ValueValidation,
    ValueValidationConfidence,
    valueValidationRef,
    ValueValidationType,
} from '@/firestore/api/valueValidation.ts';

import { ConfidencePickerProps } from './ConfidencePicker.types'

export const ConfidencePicker = (props: ConfidencePickerProps) => {
    const { type, selectedAnnotationId, focusedValueSnap } = props

    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const [valueValidationSnap, setValueValidationSnap] = useState()

    const [localConf, setLocalConf] = useState()
    
    const updateValidationData = async () => {
        const q = query(valueValidationRef, where('extractedValueId', '==', selectedAnnotationId))
        const docs = await getDocs(q)

        const doc = docs.docs[0]
        if(!doc || !doc.exists || doc.data()?.extractedValueId !== selectedAnnotationId) {
            setValueValidationSnap(undefined)
            return
        }
        if(doc.data().extractedValueId !== selectedAnnotationId) {
            throw 'Invalid data'
        }
        setValueValidationSnap(doc)
        return doc
    }

    // // Need this to handle empty response case properly and do extra validation
    useEffect(() => {
        if(!selectedAnnotationId) {
            setValueValidationSnap(undefined)
            return
        }
        updateValidationData()

        setLocalConf(undefined)
    }, [selectedAnnotationId]);

    const setAutoValidation = (type: ValueValidationType) => async (conf: ValueValidationConfidence) => {
        setLocalConf(conf)

        const snap = await updateValidationData()

        if (!snap?.id) {
            const newItemData: ValueValidation = {
                companyId: authData.company.id,
                reportId: docId as string,
                extractedValueId: selectedAnnotationId as string,
                manual: {
                    [type]: conf,
                },
            }
            debugger

            await addDoc(valueValidationRef, newItemData)
        } else {
            // if (!valueVali) {
            //     debugger
            //     console.error('Doc not found for auto validation for annotation ', annotationId)
            //     return
            // }
            debugger

            await updateDoc(snap.ref, {
                manual: {
                    ...snap.data().manual,
                    [type]: conf,
                },
            })
        }
    }
    
    const conf = localConf || valueValidationSnap?.data()?.manual?.[type];

    return (
        <Flex
            gap={16}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            <Typography.Title level={3} style={{ margin: 0 }}>
                {type.toUpperCase()}
            </Typography.Title>
            <Flex style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                <Flex gap={16}>
                    <Flex gap={4}>
                        <Tooltip title={`${type.toUpperCase()} check was successful for this element`} placement='left'>
                            <Flex
                                style={{ 
                                    height: 32, width: 32, borderRadius: '20%', background: '#52c41a', flexShrink: 0,
                                    opacity: conf === 'valid' ? 1 : 0.3,
                                    display: 'flex',
                                    cursor: 'pointer',
                                }}
                                align='center'
                                justify='center'
                                onClick={() => setAutoValidation(type)('valid')}
                            >
                                {
                                    conf === 'valid' && <CheckOutlined style={{ color: 'white' }}/>
                                }
                            </Flex>
                        </Tooltip>
                        <Tooltip title={`${type.toUpperCase()} check did not pass for this element`} placement='left'>
                            <Flex
                                align='center'
                                justify='center'
                                onClick={() => setAutoValidation(type)('invalid')}
                                style={{ height: 32, width: 32, borderRadius: '20%', background: '#ff4d4f', flexShrink: 0,
                                    opacity: conf === 'invalid' ? 1 : 0.3,
                                    display: 'flex',
                                    cursor: 'pointer',
                                }}
                            >
                                {
                                    conf === 'invalid' && <CheckOutlined style={{ color: 'white' }}/>
                                }
                            </Flex>
                        </Tooltip>
                    </Flex>

                    <Tooltip title='Validation type is not applicable' placement='left'>
                        <Button
                            onClick={() => setAutoValidation(type)('notApplicable')} icon={<CloseOutlined/>}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </Flex>
    )
}
