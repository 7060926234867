import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ReviewLinkedObjectsItem {
    content: string;
    coords: [number, number, number,number];
    label: string;
    page: number;
    linksCreated?: boolean;
    extractedValueId?: string;
}

export interface ReviewLinkedObjects {
    companyId: string;
    label: string;
    reportId: string;
    linksGroup: ReviewLinkedObjectsItem[]
    // Undefined deprecated. Should be migrated to 'ecdfNoteLink'
    type: 'ecdfNoteLink' | 'money' | undefined
}

export const REVIEW_LINKED_OBJECTS_COLLECTION = 'reviewLinkedObjects'

export const reviewLinkedObjectsRef = collection(db, REVIEW_LINKED_OBJECTS_COLLECTION)
