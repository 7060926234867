import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { identifiedBlockColorByConfidence } from '@/pages/ReviewPage';
import { addCommentForAnnotation } from '@/utils/pdfViewer/addAnnotationWithComment.ts';
import { CustomToolNames } from '@/widgets/PdfViewer2/PdfViewer2.tsx';
import {
    AnnotationConfig,
    AnnotationVariant,
    getAnnotationConfigByVariant,
} from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { stylesByCutsomTool } from '@/widgets/PdfViewer2/useToolsListener.ts';

type AnnotationType = 'Rectangle' | 'FreeText'

export const createAnnotation = async ({
    pdfInstance,
    type,
    pageIndex,
    coordinates,
    toolName,
    annotationManager,
    customData,
    relatedSnapshotId,
    showInNotesPanel = true,
    readOnly = false,
    reply,
    annotationVariant,
    padding = 0,
    annotationsList,
    annotationType = 'Rectangle',
    autosize = false,
    fondSize,
    textContent,
    annotationBase,
    richTextStyle,
    snapshotRerenderKey,
    excludeFromSummary,
    commentTitle,
}: {
    pdfInstance: WebViewerInstance,
    type: ReviewIdentifiedBlockConfidence,
    pageIndex: number,
    coordinates: [number, number, number, number],
    toolName: CustomToolNames,
    /**
     * Can be used instead of toolName for styling (when there is not manual toold for that
     */
    annotationVariant?: AnnotationVariant,
    annotationManager: Core.AnnotationManager
    customData?: Record<Partial<CustomDataKey>, string>
    relatedSnapshotId?: string
    showInNotesPanel?: boolean
    readOnly?: boolean
    autosize?: boolean
    annotationType?: AnnotationType,
    reply?: string
    fondSize?: string,
    /**
     * Optional optimization
     */
    annotationsList?: Annotations.Annotation[]
    padding?: number
    textContent?: string,
    /**
     * If on this coordinates already applied annotation with same style - skip it
     * In case if there is no snaphot or any other id
     *
     * TODO: Implement
     */
    // skipAppliedByCoordinates?: boolean
    annotationBase?: Core.Annotations.Annotation,
    /**
     * if updated and same snapshotId annotations should be rerendered
     */
    snapshotRerenderKey?: string,
    commentTitle?: string,
    excludeFromSummary: boolean
    /**
     * Text style by index
     */
    richTextStyle: Record<number, {
        'font-style'?: 'normal' | 'italic';
        'font-weight'?: 'normal' | 'bold';
        /**
                 * text-decoration: 'word' is equivalent to text-decoration: 'underline'. Values of text-decorations can also be joined by a space. For example, text-decoration: 'word line-through'
                 */
        'text-decoration'?: 'word' | 'line-through' | 'underline' | string;
        'font-family'?: string;
        'font-size'?: string;
        /**
                 * color in RGB hex format
                 */
        color?: string;
    }>
}): Promise<Core.Annotations.Annotation | void> => {
    try {
        const allannotations = annotationsList || annotationManager.getAnnotationsList()
        // We need to create manu links for the same block
        const snapshotId = relatedSnapshotId

        const prev = snapshotId?.length ? allannotations.find((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedSnapshotId')
            return customDataSnapShotId === snapshotId
        }) : undefined

        const isThisSnapshotAlreadyAnnotated = Boolean(prev) ;

        // FIXME: Make ib based on relatedSnapshotId + annotationIndex (less custom staff)
        const relatedLinkSnapshotId = customData?.relatedLinkSnapshotId
        const linkIndex = customData?.linkIndex

        // FIXME: Migrate to 'snapshotRerenderKey' field
        // Different logic for links
        const previouslyCreatedLink = (relatedLinkSnapshotId?.length && linkIndex?.length) ? allannotations.find((annotation) => {
            const customDataSnapShotId = annotation.getCustomData('relatedLinkSnapshotId')
            const customDataLinkIndex = annotation.getCustomData('linkIndex')
            return customDataSnapShotId === relatedLinkSnapshotId && customDataLinkIndex === linkIndex
        }) : false

        // If key changed, but stap id the same
        const rerenderRequired = 
            snapshotRerenderKey?.length &&
            isThisSnapshotAlreadyAnnotated &&
            prev?.getCustomData(CustomDataKey.snapshotRerenderKey) !== snapshotRerenderKey

        if (isThisSnapshotAlreadyAnnotated && !rerenderRequired) {
            return
        }

        const annotClass: Record<AnnotationType, typeof pdfInstance.Core.Annotations.RectangleAnnotation | typeof pdfInstance.Core.Annotations.FreeTextAnnotation> = {
            'Rectangle': pdfInstance.Core.Annotations.RectangleAnnotation,
            'FreeText': pdfInstance.Core.Annotations.FreeTextAnnotation,
        }

        const newAnnotationObj = rerenderRequired ? prev : (annotationBase || new annotClass[annotationType]())

        if (!newAnnotationObj) {
            console.error('Annotation type not supported', annotationType)
            return
        }

        if (textContent?.length || textContent === '') {
            newAnnotationObj.setContents(textContent);

            if(richTextStyle) {
                newAnnotationObj.setRichTextStyle(richTextStyle)
            }
        }

        const toolStyles = stylesByCutsomTool(pdfInstance)[toolName]
        const annotationVariantStyles: AnnotationConfig = annotationVariant ? getAnnotationConfigByVariant(pdfInstance)[annotationVariant] : {}

        const colorByConf = identifiedBlockColorByConfidence(pdfInstance)[type]
        const colorByAnnotVariant = annotationVariantStyles?.FillColor
        const fillColor = colorByConf || colorByAnnotVariant

        const borderStyle = annotationVariantStyles?.BorderStyle
        const strokeThinckness = annotationVariantStyles?.StrokeThickness ?? toolStyles?.StrokeThickness
        const opacity = annotationVariantStyles?.Opacity ?? toolStyles?.Opacity
        const strokeColor = annotationVariantStyles?.StrokeColor || colorByConf;
        const title = toolName || commentTitle

        newAnnotationObj.PageNumber = pageIndex + 1;
        newAnnotationObj.X = coordinates[0] - padding;
        newAnnotationObj.Y = coordinates[1] - padding;

        // newAnnotationObj.setPadding(new pdfInstance.Core.PDFNet.Rect(padding, padding, padding, padding));

        if (coordinates[2]) {
            newAnnotationObj.Width = coordinates[2] + padding * 2;
        }
        if (coordinates[3]) {
            newAnnotationObj.Height = coordinates[3] + padding * 2;
        }
        if (strokeColor) {
            newAnnotationObj.StrokeColor = strokeColor
        }
        if (fillColor) {
            newAnnotationObj.FillColor = fillColor;
        }
        if (opacity) {
            newAnnotationObj.Opacity = opacity;
        }
        if (strokeThinckness === 0 || strokeThinckness) {
            console.log('thickness set', strokeThinckness)
            newAnnotationObj.StrokeThickness = strokeThinckness;
        }
        newAnnotationObj.IsHoverable = true;
        if (toolName) {
            newAnnotationObj.ToolName = toolName;
        }
        newAnnotationObj.NoDelete = false;
        newAnnotationObj.NoMove = true;
        newAnnotationObj.NoResize = true;
        if (title) {
            newAnnotationObj.Author = title
        }
        newAnnotationObj.Listable = showInNotesPanel
        newAnnotationObj.ReadOnly = readOnly;

        if (fondSize) {
            newAnnotationObj.FontSize = fondSize;
        }
        if (autosize) {
            // debugger
            newAnnotationObj.setAutoSizeType(pdfInstance.Core.Annotations.FreeTextAnnotation.AutoSizeTypes.AUTO)
            // newAnnotationObj.setAutoSizeType('auto')
        }

        if(excludeFromSummary) {
            newAnnotationObj.setCustomData('excludeFromSummary', 'true')
        }

        (borderStyle === 'dash') && (newAnnotationObj.setBorderStyle('dash'))

        const allCustomData = customData ? {
            ...customData,
        } : {}

        if (relatedSnapshotId) {
            allCustomData['relatedSnapshotId'] = relatedSnapshotId
        }

        if(snapshotRerenderKey) {
            allCustomData[CustomDataKey.snapshotRerenderKey] = snapshotRerenderKey
        }

        allCustomData && Object.entries(allCustomData).forEach(([key, value]) => {
            if (value === undefined || value === null) return

            newAnnotationObj.setCustomData(key, value.toString());
        })
        
        annotationManager.addAnnotation(newAnnotationObj);
        annotationManager.redrawAnnotation(newAnnotationObj);

        // On first load event won't be created automatically if PDF still loading (annotations will be available with annotationsLIst anyway)
        annotationManager.trigger('annotationChanged', [[newAnnotationObj], 'add', { imported: false, force: true }]);

        if (previouslyCreatedLink) {
            // Delete previous link annotation before crating a new ony
            // Hack for fix the problem with invisible annotations diring first loading
            annotationManager.deleteAnnotation(previouslyCreatedLink)
        }

        if (reply) {
            await addCommentForAnnotation({
                annotation: newAnnotationObj,
                commentText: reply,
                annotationManager,
                instance: pdfInstance,
                silentAdd: true,
            })
        }

        return newAnnotationObj
    } catch (e) {
        debugger
        console.error('Error creating annotation', type, e)
    }
}
